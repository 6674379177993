<template>
<div class="">

<div class="">
     <div class="page-banner">
         <img src="/dist/img/coach_banner.png" style="width:100%;" alt="page-banner">
         <div class="page-title">
            <h5 class="text-white">Club/Event Merchandise</h5>
         </div>        
    </div>

       <div class="row">
            <div class="col-md-8 mb-3 mt-4">
               <div class="card card-body pt-0"> 
                    <div class="col-md-12 text-center">
                      <a target="_blank" href="https://www.teamip.com/about-us/overview"><img src="/dist/img/finedesignslogo.png" style="max-width:250px;" alt="wtc-banner"></a>
                   </div>
<p  class="mt-3 ">USA Water Ski & Wake Sports has partnered with <strong>Fine Designs</strong>, an industry leader in event merchandise, as our official online and event merchandise partner. Whether you are looking for club gear or apparel to sell at events, Fine Designs offers USA-WSWS clubs a turnkey, risk-free onsite merchandise program with a state-of-the-art retail display. </p>
<p>Fine Designs' professional staff provides a variety of cotton and performance apparel which are all custom printed on-site at each event. There is no charge for this full service, including the logo design, and a commission of all sales is paid following each event. It's that easy! With Fine Designs clubs eliminate the hassle of dealing with event merchandise, including inventory, while still receiving the benefit of sales revenue.</p>
<p>For more information email Fallon Sheffield at <a class="text-danger" href="mailto:fsheffield@usawaterski.org">fsheffield@usawaterski.org.</a></p>
                
               </div>
            </div>
            <div class="col-md-4 status_bar mt-4">
               <div class="right-sidebarlink">
                      <ul class="sidebarlink">
    <li><router-link class="" to="/club/club-insurance-information">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"><i class="far fa-address-card"></i> Club Insurance Information </h3>
                    </div>
                    </div>
            </router-link></li>
            <li><router-link class="" to="/club/quick-link-resources">

                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"> <i class="fas fa-dharmachakra"></i> Quick Link Resources</h3>
                    </div>
                    </div>
            </router-link></li>
            <li>
                <a target="_blank" class="" href="https://www.teamusa.org/usa-water-ski/safe-sport">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"> <i class="fas fa-dharmachakra"></i> Athlete Safety/Safe Sport</h3>
                    </div>
                    </div>
            </a>
            </li>
             <li><router-link class="" to="/club/practice-event-sanctioning">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"> <i class="fas fa-dharmachakra"></i> Practice and Event Sanctioning</h3>
                    </div>
                    </div>
            </router-link></li>
            <li>
                <router-link class="" to="/club/club-event-merchandise">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"><i class="fas fa-tags"></i> Club/Event Merchandise</h3>
                    </div>
                    </div>
            </router-link>
            </li>
            <li><router-link class="" to="/club/award-trophy-discounts">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"><i class="fas fa-tags"></i> Award/Trophy Discounts</h3>
                    </div>
                    </div>
            </router-link></li>
                      <!--
                             <router-link tag="li"   to="/member/online-testing">                            
                                <div class="card-header rm-border">
                                <h3 ><i class="fas fa-angle-double-right"></i> Instructor/Coach Programs</h3>                 
                                </div>
                            </router-link>
                    
                    
                            <router-link tag="li"  to="/member/drivers-program" >                              
                                <div class="card-header rm-border">
                                <h3><i class="fas fa-angle-double-right"></i> Driver Programs</h3>                 
                                </div>
                            </router-link>
                    
                    
                            <router-link tag="li"  class-active="active" to="/member/dashboard" >                          
                                <div class="card-header rm-border">
                                <h3><i class="fas fa-angle-double-right"></i> Membership Discount </h3>                 
                                </div>
                            </router-link>
                    
                     
                            <router-link tag="li"  class-active="active" to="/member/dashboard">                         
                                <div class="card-header rm-border">
                                <h3><i class="fas fa-angle-double-right"></i> Instructor/Coach Programs</h3>                 
                                </div>
                            </router-link>-->
                     
                 </ul>                  
<!-- <clubpagesidebarmenu></clubpagesidebarmenu> -->
               </div>
            </div>
         </div>

</div>

</div>
</template>


<script>

//import clubpagesidebarmenu from "./components/ClubPageSidebar";
export default {
    name: "clubeventmerchandise",
     components: {},
      data(){
        return{
        }
    }
}
</script>